import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  CheckCircle, 
  XCircle, 
  Clock, 
  Package, 
  Info, 
  CalendarDays, 
  TrendingUp,
  IndianRupee,
  AlertCircle
} from 'lucide-react';

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('Please log in to view your orders');
      setLoading(false);
      return;
    }

    let unsubscribe;
    try {
      const ordersQuery = query(
        collection(firestore, 'orders'),
        where('userId', '==', currentUser.uid),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(
        ordersQuery,
        async (snapshot) => {
          // Fetch orders with product details
          const ordersWithProducts = await Promise.all(
            snapshot.docs.map(async (orderDoc) => {
              const orderData = {
                id: orderDoc.id,
                ...orderDoc.data()
              };

              // Fetch corresponding product details
              if (orderData.productId) {
                try {
                  const productDoc = await getDoc(doc(firestore, 'products', orderData.productId));
                  if (productDoc.exists()) {
                    // Add product details to the order
                    orderData.product = {
                      name: productDoc.data().name,
                      price: productDoc.data().price,
                      rewardDays: productDoc.data().rewardDays,
                      status: productDoc.data().status
                    };
                  }
                } catch (err) {
                  console.error(`Error fetching product for order ${orderData.id}:`, err);
                }
              }

              return orderData;
            })
          );

          setOrders(ordersWithProducts);
          setLoading(false);
        },
        (error) => {
          console.error('Firestore Snapshot Error:', error);
          setError(`Failed to load orders: ${error.message}`);
          setLoading(false);
        }
      );
    } catch (err) {
      console.error('Order Query Setup Error:', err);
      setError(`Error setting up order query: ${err.message}`);
      setLoading(false);
    }

    return () => unsubscribe && unsubscribe();
  }, []);

  const renderOrderStatusBadge = (status) => {
    const statusConfig = {
      pending: {
        bg: 'bg-amber-100',
        text: 'text-amber-800',
        icon: Clock,
        label: 'Pending'
      },
      confirmed: {
        bg: 'bg-green-100',
        text: 'text-green-800',
        icon: CheckCircle,
        label: 'Confirmed'
      },
      rejected: {
        bg: 'bg-red-100',
        text: 'text-red-800',
        icon: XCircle,
        label: 'Rejected'
      },
      processing: {
        bg: 'bg-blue-100',
        text: 'text-blue-800',
        icon: Info,
        label: 'Processing'
      }
    };

    const config = statusConfig[status] || {
      bg: 'bg-slate-100',
      text: 'text-slate-800',
      icon: Info,
      label: status
    };

    const Icon = config.icon;

    return (
      <span className={`${config.bg} ${config.text} px-4 py-2 rounded-full flex items-center gap-2 font-medium text-sm`}>
        <Icon size={16} className="animate-pulse" />
        {config.label}
      </span>
    );
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-slate-50 to-blue-50">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin"></div>
          <div className="mt-4 text-slate-600 font-medium">Loading your investments...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-50 to-blue-50 p-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/80 backdrop-blur-sm rounded-3xl p-8 shadow-xl border border-red-100 max-w-md w-full"
        >
          <div className="text-center">
            <AlertCircle className="mx-auto text-red-500 mb-4" size={48} />
            <h2 className="text-2xl font-bold text-slate-800 mb-4">Unable to Load Orders</h2>
            <p className="text-slate-600">{error}</p>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-blue-50 to-purple-50 p-6">
      <div className="max-w-4xl mx-auto">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex items-center space-x-3 mb-8"
        >
          <TrendingUp className="text-blue-600" size={32} />
          <h1 className="text-3xl font-bold text-slate-800">My Investment Orders</h1>
        </motion.div>

        <AnimatePresence>
          {orders.length === 0 ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/80 backdrop-blur-sm rounded-3xl p-12 shadow-xl border border-white/20 text-center"
            >
              <Package className="mx-auto text-blue-600 mb-6" size={64} />
              <h2 className="text-2xl font-bold text-slate-800 mb-2">No Investment Orders Yet</h2>
              <p className="text-slate-600">Start your investment journey today!</p>
            </motion.div>
          ) : (
            <motion.div className="space-y-6">
              {orders.map((order, index) => (
                <motion.div
                  key={order.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ scale: 1.02 }}
                  className="group bg-white/80 backdrop-blur-sm rounded-3xl p-6 shadow-xl border border-white/20 hover:shadow-2xl transition-all duration-300"
                >
                  <div className="flex justify-between items-start gap-6">
                    <div className="space-y-4 flex-1">
                      <div>
                        <h3 className="text-xl font-bold text-slate-800 group-hover:text-blue-600 transition-colors duration-200">
                          {order.product?.name || 'Unknown Product'}
                        </h3>
                        <div className="flex items-center gap-2 mt-1 text-slate-500">
                          <CalendarDays size={16} />
                          <span className="text-sm">
                            {order.createdAt?.toDate().toLocaleDateString() || 'Date not available'}
                          </span>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div className="bg-slate-50 rounded-xl p-4">
                          <p className="text-sm text-slate-500 mb-1">Investment Amount</p>
                          <div className="flex items-center gap-1">
                            <IndianRupee size={16} className="text-blue-600" />
                            <span className="text-lg font-bold text-slate-800">
                              {order.product?.price || 'N/A'}
                            </span>
                          </div>
                        </div>
                        <div className="bg-slate-50 rounded-xl p-4">
                          <p className="text-sm text-slate-500 mb-1">Reward Period</p>
                          <div className="flex items-center gap-1">
                            <Clock size={16} className="text-blue-600" />
                            <span className="text-lg font-bold text-slate-800">
                              {order.product?.rewardDays || 'N/A'} Days
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col items-end gap-4">
                      {renderOrderStatusBadge(order.status)}
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default UserOrders;