import React, { useState, useEffect } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth, firestore, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { 
  ShoppingCart, 
  UserCheck, 
  CreditCard, 
  CheckCircle, 
  Upload, 
  ArrowRight,
  XCircle,
  TrendingUp,
  Calendar,
  Phone,
  Mail,
  Hash,ArrowLeft,
  AlertTriangle
} from 'lucide-react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const ProductPurchaseFlow = ({ product }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [purchaseStep, setPurchaseStep] = useState(0);
  const [userDetails, setUserDetails] = useState({
    phone: '',
    email: '',
  });
  const [transactionId, setTransactionId] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = () => {
      const currentUser = auth.currentUser;
      setIsLoggedIn(!!currentUser);
    };

    checkLoginStatus();
  }, []);

 if (!isLoggedIn) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', stiffness: 300 }}
        className="min-h-[80hv] bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-4"
      >
        <motion.div
          className="bg-white shadow-2xl rounded-3xl p-10 w-full max-w-md text-center border-2 border-gray-100 relative"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <UserCheck className="mx-auto mb-6 text-indigo-600" size={72} />
          <h1 className="text-4xl font-extrabold mb-4 text-gray-800">Login Required</h1>
          <p className="text-gray-600 mb-8 text-lg">
            Please authenticate to continue your investment journey.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/login')}
            className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 text-white py-4 rounded-xl text-lg font-semibold hover:from-indigo-700 hover:to-purple-800 transition duration-300 ease-in-out shadow-lg hover:shadow-xl"
          >
            Go to Login
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate(0)} // Navigates to the previous page
            className="absolute top-4 left-4 text-gray-500 hover:text-indigo-600 transition duration-300 flex items-center space-x-2"
          >
            <ArrowLeft size={24} />
            <span className="text-base font-medium">Back</span>
          </motion.button>
        </motion.div>
      </motion.div>
    );
  }

  const stepVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'spring', 
        stiffness: 300, 
        damping: 30 
      } 
    }
  };

  const handleCancel = () => {
    setPurchaseStep(0);
    // Reset form data
    setUserDetails({ phone: '', email: '' });
    setTransactionId('');
    setScreenshot(null);
  
  };

  const handleStartPurchase = () => {
    setPurchaseStep(1);
  };

  const handleUserDetailsSubmit = (e) => {
    e.preventDefault();
    setPurchaseStep(2);
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    try {
      const screenshotUrl = screenshot ? await uploadScreenshot(screenshot) : null;

      await addDoc(collection(firestore, 'orders'), {
        userId: auth.currentUser.uid,
        productId: product.id,
        phone: userDetails.phone,
        email: userDetails.email,
        transactionId,
        screenshotUrl,
        status: 'pending',
        createdAt: serverTimestamp(),
        returnPercentage: product.rewardPercentage,
      });

      setPurchaseStep(3);
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  const uploadScreenshot = async (file) => {
    const fileName = `screenshot_${Date.now()}_${file.name}`;
    const storageRef = ref(storage, 'screenshots/' + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const renderPurchaseSteps = () => {
    if (!product) return null;

    const cancelButton = (step) => (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => {
          // If at the first step, navigate to home page
          if (step === 0) {
            navigate('/products');
          } else {
            // For other steps, reset the purchase flow
            handleCancel();
          }
        }}
        className="absolute top-4 right-4 text-gray-500 hover:text-red-500 transition duration-300"
      >
        <XCircle size={32} />
      </motion.button>
    );

    switch (purchaseStep) {
      case 0:
        return (
          <motion.div 
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            className="bg-white rounded-3xl p-8 space-y-6 relative shadow-2xl border-2 border-gray-100"
          >
            {cancelButton(0)}
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="text-center"
            >
              <ShoppingCart className="mx-auto mb-4 text-indigo-600" size={72} />
              <h2 className="text-3xl font-bold mb-2 text-gray-800">{product.name}</h2>
              <p className="text-gray-600 mb-4 text-lg">{product.description}</p>
            </motion.div>
            
            <div className="flex justify-between items-center bg-indigo-50 p-4 rounded-lg">
              <div className="flex items-center space-x-3">
                <TrendingUp className="text-green-600" size={24} />
                <span className="font-bold text-green-800">
                  Daily Return: {product.rewardPercentage * 100}%
                </span>
              </div>
              <div className="flex items-center space-x-3">
                <Calendar className="text-blue-600" size={24} />
                <span className="font-bold text-blue-800">
                  {product.rewardDays} Days
                </span>
              </div>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleStartPurchase}
              className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 text-white py-4 rounded-xl flex items-center justify-center space-x-2 text-lg font-semibold hover:from-indigo-700 hover:to-purple-800 transition duration-300 ease-in-out shadow-lg hover:shadow-xl"
            >
              <span>Proceed to Investment</span>
              <ArrowRight size={24} />
            </motion.button>
          </motion.div>
        );

      case 1:
        return (
          <motion.div 
            variants={stepVariants}
            initial="hidden"
            animate="visible"
            className="bg-white rounded-3xl p-8 space-y-6 relative shadow-2xl border-2 border-gray-100"
          >
            {cancelButton(1)}
            <div className="text-center">
              <UserCheck className="mx-auto mb-4 text-indigo-600" size={72} />
              <h3 className="text-3xl font-bold mb-2 text-gray-800">Contact Information</h3>
              <p className="text-gray-600 mb-4 text-lg">Provide your contact details for investment tracking</p>
            </div>
            
            <motion.form 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              onSubmit={handleUserDetailsSubmit}
              className="space-y-6"
            >
              <div>
                <label className="block text-gray-700 mb-2 flex items-center font-semibold">
                  <Phone className="mr-2 text-indigo-600" size={20} />
                  Phone Number
                </label>
                <input
                  type="tel"
                  value={userDetails.phone}
                  onChange={(e) => setUserDetails({ ...userDetails, phone: e.target.value })}
                  className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  required
                  placeholder="Enter your phone number"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2 flex items-center font-semibold">
                  <Mail className="mr-2 text-indigo-600" size={20} />
                  Email Address
                </label>
                <input
                  type="email"
                  value={userDetails.email}
                  onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                  className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  required
                  placeholder="Enter your email address"
                />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 text-white py-4 rounded-xl flex items-center justify-center space-x-2 text-lg font-semibold hover:from-indigo-700 hover:to-purple-800 transition duration-300 ease-in-out shadow-lg hover:shadow-xl"
              >
                <span>Continue to Payment</span>
                <ArrowRight size={24} />
              </motion.button>
            </motion.form>
          </motion.div>
        );
      
        case 2:
          return (
            <motion.div 
              variants={stepVariants}
              initial="hidden"
              animate="visible"
              className="bg-white rounded-3xl p-8 space-y-6 relative shadow-2xl border-2 border-gray-100"
            >
              {cancelButton(2)}
            <div className="text-center">
              <CreditCard className="mx-auto mb-4 text-indigo-600" size={72} />
              <h3 className="text-3xl font-bold mb-2 text-gray-800">Payment Details</h3>
              <p className="text-gray-600 mb-4 text-lg">Complete your investment transaction</p>
            </div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-indigo-50 rounded-lg p-6 text-center"
            >
              <div className="mb-4">
                {/* <img 
                  src="/api/placeholder/300/200" 
                  alt="QR Code" 
                  className="mx-auto rounded-lg shadow-md"
                /> */}
                <p className="mt-4 font-bold text-lg text-gray-700">
                  UPI ID: rajukanaka2@ybl
                </p>
              </div>
            </motion.div>

            <form 
              onSubmit={handlePaymentSubmit}
              className="space-y-6"
            >
              <div>
                <label className="block text-gray-700 mb-2 flex items-center font-semibold">
                  <Hash className="mr-2 text-indigo-600" size={20} />
                  Transaction ID
                </label>
                <input
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  placeholder="Enter your transaction ID"
                  className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
                  required
                />
              </div>
              
              <div>
                <label className="block text-gray-700 mb-2 flex items-center font-semibold">
                  <Upload className="mr-2 text-indigo-600" size={20} />
                  Payment Screenshot
                </label>
                <input
                  type="file"
                  onChange={(e) => setScreenshot(e.target.files[0])}
                  className="w-full px-4 py-3 border-2 border-gray-200 rounded-xl file:mr-4 file:rounded-lg file:border-0 file:bg-indigo-50 file:px-4 file:py-2 file:text-indigo-700 hover:file:bg-indigo-100 transition duration-300"
                  accept="image/*"
                  required
                />
              </div>

              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 text-white py-4 rounded-xl flex items-center justify-center space-x-2 text-lg font-semibold hover:from-indigo-700 hover:to-purple-800 transition duration-300 ease-in-out shadow-lg hover:shadow-xl"
              >
                <span>Submit Payment Proof</span>
                <CheckCircle size={24} />
              </motion.button>
            </form>
          </motion.div>
        );
      
        case 3:
          return (
            <motion.div 
              variants={stepVariants}
              initial="hidden"
              animate="visible"
              className="bg-white rounded-3xl p-8 text-center space-y-6 relative shadow-2xl border-2 border-gray-100"
            >
              {cancelButton(3)}
            <CheckCircle className="mx-auto text-green-600" size={72} />
            
            <div>
              <h3 className="text-4xl font-bold text-green-600 mb-4">
                Investment Confirmed
              </h3>
              <p className="text-gray-700 mb-6 text-lg">
                Your investment has been received. A confirmation will be sent to {userDetails.email}. 
                The product will be added to your orders after admin verification.
              </p>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="bg-yellow-50 border-l-6 border-yellow-500 p-6 rounded-xl"
            >
              <p className="text-yellow-800 flex items-center justify-center space-x-3 text-base">
                <AlertTriangle className="text-yellow-600" size={24} />
                <span>Daily returns are subject to terms and conditions</span>
              </p>
            </motion.div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/products')}
              className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 text-white py-4 rounded-xl flex items-center justify-center space-x-2 text-lg font-semibold hover:from-indigo-700 hover:to-purple-800 transition duration-300 ease-in-out shadow-lg hover:shadow-xl"
            >
              <span>View Products</span>
              <ArrowRight size={24} />
            </motion.button>
          </motion.div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className=" bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        {renderPurchaseSteps()}
      </div>
    </div>
  );
};

export default ProductPurchaseFlow;