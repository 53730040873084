import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Home, 
  ShoppingCart, 
  LogIn, 
  LogOut, 
  DollarSign, 
  Wallet as WalletIcon,
  User,
  Menu,
  X
} from 'lucide-react';

import ProductListingPage from './components/ProductListingPage';
import ProductPurchaseFlow from './components/ProductPurchaseFlow';
import LoginPage from './components/LoginPage';
import Wallet from './components/Wallet';
import { auth } from './firebase';
import AdminDashboard from './components/AdminDashboard';
import Orders from './components/UserOrdersPage';

const App = () => {
  const [user, setUser] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      unsubscribe();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setIsMobileMenuOpen(false);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const animationVariants = {
    header: {
      initial: { opacity: 0, y: -50 },
      animate: { 
        opacity: 1, 
        y: 0,
        transition: { 
          duration: 0.7,
          ease: "easeOut",
          type: "spring",
          stiffness: 100
        }
      }
    },
    navItem: {
      initial: { opacity: 0, x: -20 },
      animate: { 
        opacity: 1, 
        x: 0,
        transition: { 
          duration: 0.5,
          ease: "easeOut"
        }
      },
      hover: { 
        scale: 1.05,
        y: -2,
        transition: { 
          duration: 0.3,
          type: "spring",
          stiffness: 300
        }
      },
      tap: { 
        scale: 0.95,
        transition: { duration: 0.2 }
      }
    },
    logo: {
      initial: { rotate: -180, scale: 0 },
      animate: { 
        rotate: 0,
        scale: 1,
        transition: {
          duration: 0.8,
          type: "spring",
          stiffness: 200,
          damping: 20
        }
      },
      hover: {
        scale: 1.1,
        rotate: 360,
        transition: {
          duration: 0.8,
          type: "spring",
          stiffness: 200
        }
      }
    },
    mobileMenu: {
      initial: { 
        opacity: 0, 
        x: '100%',
        scale: 0.9
      },
      animate: { 
        opacity: 1, 
        x: 0,
        scale: 1,
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 30,
          duration: 0.5
        }
      },
      exit: { 
        opacity: 0, 
        x: '100%',
        scale: 0.9,
        transition: {
          duration: 0.4
        }
      }
    }
  };

  const navigationItems = user ? [
    { icon: Home, text: "Home", path: "/" },
    { icon: ShoppingCart, text: "Products", path: "/products" },
    { icon: WalletIcon, text: "Wallet", path: "/wallet" }
  ] : [
    { icon: Home, text: "Home", path: "/" },
    { icon: ShoppingCart, text: "Products", path: "/products" }
  ];

  const renderDesktopNavigation = () => (
    <nav className="hidden md:flex items-center space-x-6">
      {navigationItems.map((item, index) => (
        <motion.div 
          key={item.path}
          variants={animationVariants.navItem}
          initial="initial"
          animate="animate"
          whileHover="hover"
          whileTap="tap"
          custom={index}
          transition={{ delay: index * 0.1 }}
        >
          <Link 
            to={item.path} 
            className="text-gray-700 hover:text-blue-600 flex items-center transition-all duration-300 px-4 py-2 rounded-lg hover:bg-blue-50"
          >
            <item.icon className="mr-2" /> {item.text}
          </Link>
        </motion.div>
      ))}
      
      {user ? (
        <motion.div
          variants={animationVariants.navItem}
          initial="initial"
          animate="animate"
          whileHover="hover"
          whileTap="tap"
          className="flex items-center space-x-4"
        >
          <div className="flex items-center space-x-2 bg-blue-50 px-4 py-2 rounded-lg">
            <User className="text-blue-600" />
            <span className="text-gray-800 truncate max-w-[100px]">{user.email}</span>
          </div>
          <motion.button
            onClick={handleLogout}
            className="bg-gradient-to-r from-red-500 to-red-600 text-white px-4 py-2 rounded-lg hover:from-red-600 hover:to-red-700 flex items-center transition-all duration-300 shadow-lg hover:shadow-xl"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <LogOut className="mr-2" size={16} /> Logout
          </motion.button>
        </motion.div>
      ) : (
        <motion.div
          variants={animationVariants.navItem}
          initial="initial"
          animate="animate"
          whileHover="hover"
          whileTap="tap"
        >
          <Link 
            to="/login" 
            className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-2 rounded-lg hover:from-blue-600 hover:to-blue-700 flex items-center transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            <LogIn className="mr-2" /> Login
          </Link>
        </motion.div>
      )}
    </nav>
  );

  const renderMobileNavigation = () => (
    <>
      <motion.button 
        className="md:hidden text-gray-800 z-60 p-2 hover:bg-blue-50 rounded-lg"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </motion.button>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            key="mobile-menu"
            variants={animationVariants.mobileMenu}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed top-0 right-0 w-64 h-full bg-white shadow-2xl z-50 md:hidden rounded-l-2xl"
          >
            <div className="flex flex-col p-6 space-y-4 mt-16">
              {navigationItems.map((item, index) => (
                <motion.div
                  key={item.path}
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Link 
                    to={item.path} 
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="flex items-center text-gray-700 hover:text-blue-600 transition-all duration-300 p-2 hover:bg-blue-50 rounded-lg"
                  >
                    <item.icon className="mr-3" /> {item.text}
                  </Link>
                </motion.div>
              ))}

              {user ? (
                <div className="space-y-4 pt-4 border-t">
                  <div className="flex items-center space-x-2 text-gray-800 bg-blue-50 p-3 rounded-lg">
                    <User className="text-blue-600" />
                    <span className="truncate max-w-[150px]">{user.email}</span>
                  </div>
                  <motion.button
                    onClick={handleLogout}
                    className="w-full bg-gradient-to-r from-red-500 to-red-600 text-white px-4 py-3 rounded-lg hover:from-red-600 hover:to-red-700 flex items-center justify-center transition-all duration-300 shadow-lg hover:shadow-xl"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <LogOut className="mr-2" /> Logout
                  </motion.button>
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <Link 
                    to="/login" 
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 py-3 rounded-lg hover:from-blue-600 hover:to-blue-700 flex items-center justify-center transition-all duration-300 shadow-lg hover:shadow-xl"
                  >
                    <LogIn className="mr-2" /> Login
                  </Link>
                </motion.div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-50 via-blue-50 to-white">
        <motion.header 
          variants={animationVariants.header}
          initial="initial"
          animate="animate"
          className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 
            ${isScrolled ? 'bg-white/90 backdrop-blur-md shadow-lg' : 'bg-transparent'}`}
        >
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <Link 
              to="/" 
              className="text-xl md:text-2xl font-bold text-blue-600 flex items-center hover:text-blue-700 transition-all duration-300"
            >
              
              <span className="bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
                LearnAndGrab
              </span>
            </Link>
            
            {renderDesktopNavigation()}
            {renderMobileNavigation()}
          </div>
        </motion.header>

        <main className="flex-1 mt-16">
          <AnimatePresence mode="wait">
            <Routes>
              {/* Routes remain the same */}
              <Route 
                path="/" 
                element={
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <ProductListingPage />
                  </motion.div>
                } 
              />
              <Route 
                path="/products" 
                element={
                  user ? (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Orders />
                    </motion.div>
                  ) : (
                    <Navigate to="/login" replace />
                  )
                } 
              />
              <Route 
                path="/purchase/:productId" 
                element={
                  user ? (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <ProductPurchaseFlow />
                    </motion.div>
                  ) : (
                    <Navigate to="/login" replace />
                  )
                } 
              />
              <Route path="/login" element={<LoginPage />} />
              <Route 
                path="/wallet"
                element={
                  user ? (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Wallet />
                    </motion.div>
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
          </AnimatePresence>
        </main>
      </div>
    </Router>
  );
};

export default App;