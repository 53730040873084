import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Wallet, 
  ArrowDownCircle, 
  Clock, 
  AlertCircle,
  TrendingUp,
  HistoryIcon,
  ChevronRight,
  IndianRupee
} from 'lucide-react';

const WalletPage = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [confirmedOrders, setConfirmedOrders] = useState([]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [ordersWithProducts, setOrdersWithProducts] = useState([]);

  useEffect(() => {
    fetchUserOrders();
    fetchWithdrawalHistory();
  }, []);

  const fetchProductDetails = async (productId) => {
    try {
      const productDoc = await getDoc(doc(firestore, 'products', productId));
      if (productDoc.exists()) {
        return productDoc.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching product:', error);
      return null;
    }
  };

  const fetchWithdrawalHistory = async () => {
    try {
      const withdrawalsRef = collection(firestore, 'withdrawals');
      const q = query(
        withdrawalsRef,
        where('userId', '==', auth.currentUser.uid)
      );
      
      const querySnapshot = await getDocs(q);
      const withdrawals = [];
      
      querySnapshot.forEach((doc) => {
        withdrawals.push({ id: doc.id, ...doc.data() });
      });
      
      setWithdrawalHistory(withdrawals);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
    }
  };

  const fetchUserOrders = async () => {
    try {
      const ordersRef = collection(firestore, 'orders');
      const q = query(
        ordersRef,
        where('userId', '==', auth.currentUser.uid),
        where('status', '==', 'confirmed')
      );
      
      const querySnapshot = await getDocs(q);
      const orders = [];
      const ordersWithProductDetails = [];
      
      for (const doc of querySnapshot.docs) {
        const orderData = { id: doc.id, ...doc.data() };
        orders.push(orderData);
        
        // Fetch associated product details
        const productDetails = await fetchProductDetails(orderData.productId);
        if (productDetails) {
          ordersWithProductDetails.push({
            ...orderData,
            productDetails: {
              price: productDetails.price,
              rewardDays: productDetails.rewardDays,
              rewardPercentage: productDetails.rewardPercentage,
              name: productDetails.name
            }
          });
        }
      }
      
      setConfirmedOrders(orders);
      setOrdersWithProducts(ordersWithProductDetails);
      calculateTotalBalance(ordersWithProductDetails);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const calculateTotalBalance = (orders) => {
    let balance = 0;
    
    orders.forEach(order => {
      const orderDate = order.createdAt.toDate();
      const currentDate = new Date();
      const daysDifference = Math.floor((currentDate - orderDate) / (1000 * 60 * 60 * 24));
      console.log(daysDifference);
      
      // Only calculate returns if we haven't exceeded the reward days
      if (daysDifference <= order.productDetails.rewardDays) {
        // Calculate daily returns for the valid period
        const daysToCalculate = daysDifference;
        const dailyReturn = order.productDetails.price * (order.productDetails.rewardPercentage);
        const totalReturns = dailyReturn * daysToCalculate;
        
        balance += totalReturns;
      } else {
        // If we've exceeded reward days, only calculate returns for the reward period
        const dailyReturn = order.productDetails.price * (order.productDetails.rewardPercentage);
        const totalReturns = dailyReturn * order.productDetails.rewardDays;
        
        balance += totalReturns;
      }
    });

    // Subtract completed withdrawals
    const totalWithdrawals = withdrawalHistory
      .filter(withdrawal => withdrawal.status === 'completed')
      .reduce((total, withdrawal) => total + withdrawal.amount, 0);

    balance -= totalWithdrawals;
    setTotalBalance(Math.max(0, balance)); // Ensure balance never goes below 0
  };

  const handleWithdrawalRequest = async () => {
    if (!withdrawalAmount || parseFloat(withdrawalAmount) <= 0 || parseFloat(withdrawalAmount) > totalBalance) {
      alert('Please enter a valid withdrawal amount');
      return;
    }

    try {
      await addDoc(collection(firestore, 'withdrawals'), {
        userId: auth.currentUser.uid,
        amount: parseFloat(withdrawalAmount),
        status: 'pending',
        createdAt: serverTimestamp(),
        requestedDate: new Date().toISOString()
      });

      setWithdrawalAmount('');
      fetchWithdrawalHistory();
    } catch (error) {
      console.error('Error submitting withdrawal request:', error);
    }
  };
  
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-slate-50 to-blue-50">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin"></div>
          <p className="mt-4 text-slate-600 font-medium">Loading your wallet...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-blue-50 to-purple-50 p-6">
      <div className="max-w-4xl mx-auto space-y-8">
        {/* Balance Card */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/80 backdrop-blur-sm rounded-3xl p-8 shadow-xl border border-white/20"
        >
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center space-x-4">
              <div className="relative">
                <div className="absolute inset-0 bg-blue-100 rounded-full blur-lg opacity-40"></div>
                <Wallet className="relative text-blue-600" size={48} />
              </div>
              <div>
                <h2 className="text-2xl font-bold text-slate-800">Your Wallet</h2>
                <p className="text-slate-600">Available Balance</p>
              </div>
            </div>
            <div className="text-right">
              <h3 className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                ₹{totalBalance.toFixed(2)}
              </h3>
            </div>
          </div>

          {/* Withdrawal Form */}
          <div className="mt-8 space-y-4">
            <div className="relative">
              <IndianRupee className="absolute left-4 top-1/2 -translate-y-1/2 text-slate-400" size={20} />
              <input
                type="number"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                placeholder="Enter withdrawal amount"
                className="w-full pl-12 pr-4 py-4 bg-slate-50/50 border border-slate-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleWithdrawalRequest}
              className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white py-4 rounded-xl flex items-center justify-center space-x-2 text-lg font-medium hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30"
            >
              <span>Request Withdrawal</span>
              <ArrowDownCircle className="animate-bounce" size={20} />
            </motion.button>
          </div>
        </motion.div>

        {/* Active Investments */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white/80 backdrop-blur-sm rounded-3xl p-8 shadow-xl border border-white/20"
        >
          <div className="flex items-center space-x-3 mb-8">
            <TrendingUp className="text-blue-600" size={24} />
            <h3 className="text-2xl font-bold text-slate-800">Active Investments</h3>
          </div>
          <div className="space-y-4">
            {ordersWithProducts.map(order => (
              <motion.div
                key={order.id}
                whileHover={{ scale: 1.01 }}
                className="group bg-slate-50/50 hover:bg-white rounded-xl p-6 border border-slate-200/50 transition-all duration-300 hover:shadow-lg"
              >
                <div className="flex justify-between items-center">
                  <div className="space-y-2">
                    <h4 className="font-semibold text-slate-800 text-lg group-hover:text-blue-600 transition-colors duration-200">
                      {order.productDetails.name}
                    </h4>
                    <p className="text-2xl font-bold text-slate-800">
                      ₹{order.productDetails.price}
                    </p>
                    <div className="space-y-1">
                      <p className="text-sm text-slate-600 flex items-center space-x-2">
                        <span>Daily Return:</span>
                        <span className="font-medium text-green-600">
                          ₹{(order.productDetails.price * order.productDetails.rewardPercentage).toFixed(2)}
                        </span>
                      </p>
                      <p className="text-sm text-slate-600 flex items-center space-x-2">
                        <span>Duration:</span>
                        <span className="font-medium">{order.productDetails.rewardDays} days</span>
                      </p>
                    </div>
                  </div>
                  <div className="text-right space-y-2">
                    <p className="text-sm text-slate-500">Started</p>
                    <p className="text-sm font-medium text-slate-800">
                      {order.createdAt.toDate().toLocaleDateString()}
                    </p>
                    <ChevronRight className="ml-auto text-slate-400 group-hover:text-blue-600 transition-colors duration-200" size={20} />
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Withdrawal History */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white/80 backdrop-blur-sm rounded-3xl p-8 shadow-xl border border-white/20"
        >
          <div className="flex items-center space-x-3 mb-8">
            <HistoryIcon className="text-blue-600" size={24} />
            <h3 className="text-2xl font-bold text-slate-800">Withdrawal History</h3>
          </div>
          <div className="space-y-4">
            {withdrawalHistory.map(withdrawal => (
              <motion.div
                key={withdrawal.id}
                whileHover={{ scale: 1.01 }}
                className="group bg-slate-50/50 hover:bg-white rounded-xl p-6 border border-slate-200/50 transition-all duration-300 hover:shadow-lg"
              >
                <div className="flex justify-between items-center">
                  <div className="space-y-2">
                    <p className="text-xl font-bold text-slate-800">₹{withdrawal.amount}</p>
                    <div className="flex items-center space-x-2 text-slate-500">
                      <Clock size={16} />
                      <p className="text-sm">
                        {new Date(withdrawal.requestedDate).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div className={`px-4 py-2 rounded-full font-medium text-sm ${
                    withdrawal.status === 'completed' 
                      ? 'bg-green-100 text-green-700' 
                      : 'bg-amber-100 text-amber-700'
                  }`}>
                    {withdrawal.status.charAt(0).toUpperCase() + withdrawal.status.slice(1)}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WalletPage;